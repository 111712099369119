import currencyPipe from '../pipes/currency.pipe'
export const getUpdatedAt = (subscription: Subscription): string | null => {
  return subscription?.updatedAt || null
}

export const getLinkPrefix = (subscription: Subscription): string => {
  const manufactureSlug = subscription.version.model!.manufacture.slug
  const modelSlug = subscription.version.model!.slug
  const versionSlug = subscription.version.slug
  return `/assinar/oferta/${manufactureSlug}/${modelSlug}/${versionSlug}/`
}

export const getLocalLink = (subscription: Subscription): string => {
  return `${getLinkPrefix(subscription)}${subscription.id}`
}

export const getLink = (subscription: Subscription): string => {
  return `${subscription.link}?utm_source=comparacar&utm_medium=site`
}

export const getImages = (subscription: Subscription): string[] => {
  return [
    ...(subscription.photos?.map(item => item.url) || []),
    ...(subscription.version.photos?.map(item => item.url) || [])
  ]
}

export const getTitle = (subscription: Subscription): string => {
  const manufactureName = subscription.version.model!.manufacture.name
  const modelName = subscription.version.model!.name
  return `${manufactureName} ${modelName}`
}

export const getDescription = (subscription: Subscription): string => {
  return `Plano de ${subscription.month} meses`
}

export const getDescription2 = (subscription: Subscription): string => {
  return `Franquia mensal ${subscription.km} Km`
}

export const getPrice = (subscription: Subscription): string => {
  return subscription.hidePrice ? 'Preço sob-consulta*' : `${currencyPipe(parseInt(subscription.price))}/mês`
}

export const normalizeSubscription = (subscription: Subscription): ResultCard => {
  return {
    id: subscription.id,
    updatedAt: getUpdatedAt(subscription),
    type: 'SUBSCRIPTION',
    linkPrefix: getLinkPrefix(subscription),
    localLink: getLocalLink(subscription),
    link: getLink(subscription),
    hidePrice: subscription.hidePrice || false,
    isHotOffer: false,
    images: {
      origin: subscription.photos?.map(item => item.url) || [],
      version: subscription.version.photos?.map(item => item.url) || []
    },
    title: getTitle(subscription),
    location: '',
    subtitle: subscription.version.name,
    description: getDescription(subscription),
    description2: getDescription2(subscription),
    price: getPrice(subscription),
    priceNumber: parseInt(subscription.price),
    brand: subscription.source?.name || null,
    isAuthorizedPartner: subscription.source?.authorizedPartner || false,
    hasApplePlay: subscription.version?.smartphoneConnectivity || null,
    hasAndroidAuto: subscription.version?.smartphoneConnectivity || null,
    hasAllWheelDrive: subscription.version.allWheelDrive || null,
    showPreLinkOffer: subscription.showPreLinkOffer,
    tags: subscription.tags || null,
    version: subscription.version,
    km: subscription.km,
    month: subscription.month
  }
}

export const normalizeSubscriptions = (subscriptions: Subscription[]): ResultCard[] =>
  subscriptions.map<ResultCard>(item => normalizeSubscription(item))
