import { Stack, useMediaQuery } from '@mui/material'
import Grid from '@mui/material/Grid2'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { useRouter } from 'next/router'
import React from 'react'
import { useRecoilValue } from 'recoil'

import BrandListCarrousel from './BrandListCarrousel'
import Tab from './Tab'
import SearchInput from '../generic/SearchInput'
import { carouselIconsState } from '../state/brands'

interface iMainSearch {
  engine: engine
}

const MainSearch: React.FC<iMainSearch> = ({ engine }) => {
  const isMobile = useMediaQuery('(max-width:700px)')
  const carouselIcons = useRecoilValue(carouselIconsState)

  const router = useRouter()

  const updateUrl = (searchTerm: string) => {
    if (engine === 'subscribe') {
      router.push(`/assinar/carro/${searchTerm}`)
      return
    }
    if (engine === 'buy') {
      router.push(`/comprar/carro/${searchTerm}`)
      return
    }
    if (engine === 'rate') {
      router.push(`/avalieseucarro/carro/${searchTerm}`)
      return
    }
  }

  const handleToggleSearch = (_event: React.MouseEvent<HTMLElement>, newSearchOption: string | null) => {
    if (newSearchOption === 'subscribe') {
      router.push(`/assinar`)
      return
    }
    if (newSearchOption === 'buy') {
      router.push(`/comprar`)
      return
    }
    if (newSearchOption === 'rate') {
      router.push(`/avalieseucarro`)
      return
    }
  }

  return (
    <>
      <Tabs
        sx={{
          ml: { sm: 3, xs: 2 }
        }}
        value={engine}
        onChange={handleToggleSearch}
        aria-label="basic tabs example"
      >
        <Tab label={isMobile ? 'Assinar' : 'Carros por Assinatura'} value="subscribe" />
        {/*<Tab data-cy="TabComprar" label="Comprar" value="buy" />*/}
        <Tab data-cy="rate" label={isMobile ? 'Quanto vale' : 'Avalie seu carro'} value="rate" />
      </Tabs>
      <Grid
        container
        paddingY={6}
        paddingX={3}
        bgcolor="background.paper"
        boxShadow={2}
        data-testid="SubscriptionSearch"
        borderRadius="0px 0px 8px 8px"
      >
        <Grid size={12} mb={7}>
          <Stack spacing={2}>
            <SearchInput
              fullWidth
              seeAllButton={engine !== 'rate'}
              hideManufacture={engine === 'rate'}
              onSelect={option => {
                updateUrl(option.value)
              }}
            />
          </Stack>
        </Grid>
        <Grid size={12} mb={2}>
          {engine === 'subscribe' && (
            <Typography variant="body1" align="center">
              Compare os principais sites de <strong>ofertas de assinatura de carros</strong> de uma só vez:
            </Typography>
          )}
          {engine === 'rate' && (
            <Typography variant="h6" align="center">
              Descubra o preço médio do seu carro
            </Typography>
          )}
        </Grid>
        {engine !== 'rate' && (
          <Grid size={{ xs: 12, md: 12 }}>
            <BrandListCarrousel brands={carouselIcons.map(brand => ({ ...brand, width: 100, height: 54.4 }))} />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default MainSearch
