import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid2'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import NextLink from 'next/link'
import Script from 'next/script'
import React from 'react'

import ComparaCarIcon from '../icons/brands/ComparaCar'

type Itens = { link: string; title: string }

type MenuItemType = {
  header: string
  itens: Itens[]
}

function Footer() {
  const menuItens: MenuItemType[] = [
    {
      header: 'Páginas',
      itens: [
        {
          link: '/sobre',
          title: 'Sobre'
        },
        {
          link: '/termos_de_uso',
          title: 'Termos de uso'
        }
      ]
    },
    {
      header: 'Conteúdo',
      itens: [
        {
          link: '/conteudo',
          title: 'Todos'
        },
        {
          link: '/conteudo/o-que-e-carro-por-assinatura',
          title: 'O que é carro por assinatura?'
        }
      ]
    },
    {
      header: 'Serviços',
      itens: [
        {
          link: '/comprar',
          title: 'Comprar'
        },
        {
          link: '/assinar',
          title: 'Carro por Assinatura'
        }
      ]
    }
  ]

  return (
    <Container sx={{ height: '100%', mt: 10 }}>
      <Stack>
        <Box>
          <ComparaCarIcon />
          <Typography component="div" variant="caption" textTransform="unset">
            Seus sites favoritos de ofertas de carro
          </Typography>
        </Box>
        <Grid container spacing={2} columns={4}>
          {menuItens.map((value, i) => (
            <Grid size={{ xs: 2, md: 1 }} key={i}>
              <Box margin={{ xs: '0 auto', sm: 'unset ' }} maxWidth={{ xs: 180, sm: 'unset ' }}>
                <Typography variant="subtitle1" fontWeight="600" mb={2}>
                  {value.header}
                </Typography>
                {value.itens.map(({ link, title }, index) => (
                  <NextLink key={index} href={link} passHref legacyBehavior>
                    <Link color="textSecondary" variant="body2" display="block" mb={2}>
                      {title}
                    </Link>
                  </NextLink>
                ))}
              </Box>
            </Grid>
          ))}
          <Grid size={{ xs: 2, md: 1 }}>
            <Box maxWidth={180}>
              <Typography variant="subtitle1" fontWeight="600" mb={2}>
                Siga-nos
              </Typography>
              <Box sx={{ display: 'flex', color: 'text.secondary' }}>
                <IconButton color="inherit" href="https://twitter.com/Compara_car" rel="noreferrer" target="_blank">
                  <TwitterIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  href="https://www.facebook.com/ComparaCarOficial"
                  rel="noreferrer"
                  target="_blank"
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  href="https://www.linkedin.com/company/comparacar/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <LinkedInIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  href="https://www.instagram.com/comparacar_oficial/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <InstagramIcon />
                </IconButton>
              </Box>
              <div id="ra-verified-seal">
                <Script
                  type="text/javascript"
                  id="ra-embed-verified-seal"
                  src="https://s3.amazonaws.com/raichu-beta/ra-verified/bundle.js"
                  data-id="YWo3Wm13WHBGVmRab1FVeTpjb21wYXJhY2Fy"
                  data-target="ra-verified-seal"
                  data-model="2"
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Stack>
      <Divider orientation="horizontal" sx={{ marginY: 5 }} />
      <Typography color="textSecondary" variant="body2" mb={5}>
        © {new Date().getFullYear()} ComparaCar CNPJ 35.212.289/0001-97. Todos os direitos reservados.
      </Typography>
    </Container>
  )
}

export default Footer
